import { Observable, Subscription } from "rxjs";
import * as firestore from "firebase/firestore";
import {
  OffersData,
  OffersObservable,
  options,
} from "../../services/GetOffersObservableService";
import {
  OfferResume,
  OperationResume,
} from "../../../../pages/offers/entities/data-objects/OperationResume";
import { DateTime } from "luxon";
import { parseDate } from "../../../../utils/date";

export class OffersObservableImpl
  extends Observable<OffersData>
  implements OffersObservable
{
  private lastSellOffersData: OperationResume[] = [];
  private lastBuyOffersData: OperationResume[] = [];
  private lastPldValueFromSubmarket = 0;
  private firestoreListenerSell: () => void = () => {};
  private firestoreListenerBuy: () => void = () => {};
  private options: options = {
    amount: null,
    value: null,
    productId: "",
    submarket: "",
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: OffersData) => void) | null | undefined;
  }[] = [];

  subscribeWithOptions(
    options: options,
    next?: ((value: OffersData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0) {
        this.firestoreListenerSell();
        this.firestoreListenerBuy();
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.options = options;
    this.startFirestoreListener();
  }

  private startFirestoreListener() {
    const lastMonth = DateTime.now()
      .startOf("month")
      .minus({ day: 1 })
      .startOf("month");
    this.firestoreListenerSell();

    const queryFilters: firestore.QueryConstraint[] = [
      firestore.where("expired", "==", false),
      firestore.where("market", "==", "CONTRACT"),
      firestore.where("product.id", "==", this.options.productId),
      firestore.where("submarket", "==", this.options.submarket),

      firestore.where(
        "deliveryPeriodStartMonth",
        "==",
        lastMonth.toFormat("MM/yyyy")
      ),
      firestore.where(
        "deliveryPeriodEndMonth",
        "==",
        lastMonth.toFormat("MM/yyyy")
      ),
    ];

    const query = firestore.query(
      firestore.collection(firestore.getFirestore(), `operation`),
      firestore.orderBy("sellOffer.priceValue", "asc"),
      firestore.limit(30),
      firestore.where("sellOffer.status", "in", [
        "ABERTA",
        "PARCIALMENTE ENCERRADA",
      ]),
      ...queryFilters
    );

    this.firestoreListenerSell = firestore.onSnapshot(
      query,
      async (snapshot) => {
        this.subscribers.forEach((subscriber) => {
          if (!subscriber.next) {
            return;
          }

          const sellOffers = snapshot.docs
            .filter((doc) => {
              return (
                doc.data().sellOffer !== null &&
                doc.data().offerCloseDateTime.toDate().getTime() >=
                  new Date().getTime()
              );
            })
            .map((doc) => {
              return this.firestoreDocToOffer(doc, "sellOffer");
            });

          this.lastSellOffersData = sellOffers;

          subscriber.next({
            sellOffers,
            buyOffers: this.lastBuyOffersData,
            amount: this.calculateAmount()?.amount || null,
            value: this.calculateValue()?.value || null,
            offersReached: this.calculateAmount()
              ? this.calculateAmount()?.offersReached || 0
              : this.calculateValue()?.offersReached || 0,
          });
        });
      }
    );

    const queryBuy = firestore.query(
      firestore.collection(firestore.getFirestore(), `operation`),
      firestore.orderBy("buyOffer.priceValue", "desc"),
      firestore.limit(30),
      firestore.where("buyOffer.status", "in", [
        "ABERTA",
        "PARCIALMENTE ENCERRADA",
      ]),
      ...queryFilters
    );

    this.firestoreListenerBuy = firestore.onSnapshot(
      queryBuy,
      async (snapshot) => {
        this.subscribers.forEach((subscriber) => {
          if (!subscriber.next) {
            return;
          }

          const buyOffers = snapshot.docs
            .filter((doc) => {
              return (
                doc.data().buyOffer !== null &&
                doc.data().offerCloseDateTime.toDate().getTime() >=
                  new Date().getTime()
              );
            })
            .map((doc) => {
              return this.firestoreDocToOffer(doc, "buyOffer");
            });

          this.lastBuyOffersData = buyOffers;

          subscriber.next({
            buyOffers,
            sellOffers: this.lastSellOffersData,
            amount: this.calculateAmount()?.amount || null,
            value: this.calculateValue()?.value || null,
            offersReached: this.calculateAmount()
              ? this.calculateAmount()?.offersReached || 0
              : this.calculateValue()?.offersReached || 0,
          });
        });
      }
    );
  }

  private firestoreDocToOffer(
    doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>,
    type: "buyOffer" | "sellOffer"
  ): OperationResume {
    const docData = doc.data();

    const operation = new OperationResume(
      doc.id,
      false,
      docData.product.id,
      docData.product.measurementUnit,
      docData.submarket,
      type === "sellOffer"
        ? null
        : new OfferResume(
            doc.id + "b",
            doc.id,
            docData[type].volume,
            docData[type].remainingVolumeToBeNegotiated,
            docData[type].priceValue,
            docData[type].currentBestBidValue,
            docData[type].currentBestBidVolume,
            docData[type].remainingVolumeToBeNegotiated,
            docData[type].priceValue -
              Number(docData.pldValues[docData.submarket]),
            docData[type].priceType === "Atrelado ao PLD",
            0,
            docData[type].status,
            docData[type].priceType
          ),
      type === "buyOffer"
        ? null
        : new OfferResume(
            doc.id + "s",
            doc.id,
            docData[type].volume,
            docData[type].remainingVolumeToBeNegotiated,
            docData[type].priceValue,
            docData[type].currentBestBidValue,
            docData[type].currentBestBidVolume,
            docData[type].remainingVolumeToBeNegotiated,
            docData[type].priceValue -
              Number(docData.pldValues[docData.submarket]),
            docData[type].priceType === "Atrelado ao PLD",
            0,
            docData[type].status,
            docData[type].priceType
          ),
      new Date(parseDate(docData.offerCloseDateTime) ?? NaN),
      docData.status,
      new Date(parseDate(docData.deliveryPeriodStart) ?? NaN),
      new Date(parseDate(docData.deliveryPeriodEnd) ?? NaN),
      docData.reTusd,
      docData.participantClassName
    );

    return operation;
  }

  private calculateAmount(): {
    offersReached: number;
    amount: number;
  } | null {
    if (this.options.amount !== null) {
      return null;
    }

    let remainingValue = this.options.value || 0;
    let amount = 0;

    let currentOffer = 0;
    while (
      remainingValue > 0 &&
      currentOffer < this.lastSellOffersData.length
    ) {
      let currentOfferData =
        this.lastSellOffersData[currentOffer++].getSellOffer()!;
      const value = Math.min(
        remainingValue,
        currentOfferData.getValue()! * currentOfferData.getRemainingVolume()
      );
      amount += value / currentOfferData.getValue()!;
      remainingValue -= value;
    }

    return remainingValue === 0
      ? {
          amount,
          offersReached: currentOffer,
        }
      : null;
  }

  private calculateValue(): {
    offersReached: number;
    value: number;
  } | null {
    if (this.options.value !== null) {
      return null;
    }

    let remainingAmount = this.options.amount || 0;
    let value = 0;

    let currentOffer = 0;
    while (
      remainingAmount > 0 &&
      currentOffer < this.lastBuyOffersData.length
    ) {
      let currentOfferData =
        this.lastBuyOffersData[currentOffer++].getBuyOffer()!;
      const remainingVolume = Math.min(
        remainingAmount,
        currentOfferData.getRemainingVolume()
      );
      value += remainingVolume * currentOfferData.getValue()!;
      remainingAmount -= remainingVolume;
    }

    return remainingAmount === 0
      ? {
          value,
          offersReached: currentOffer,
        }
      : null;
  }
}
