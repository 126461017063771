import React from "react";
import * as firestore from "firebase/firestore";
import { useFirestoreQuery } from "../../../hooks/useFirestoreQuery";
import { Bid } from "../../../types/offer";

export function useGetBid(
  operationId: string | null | undefined,
  offerType: "COMPRA" | "VENDA" | null | undefined,
  prospectId: number | null | undefined
) {
  const {
    data: [bidData],
  } = useFirestoreQuery(() => {
    if (
      !operationId ||
      !offerType ||
      prospectId === null ||
      prospectId === undefined
    ) {
      return [];
    }

    console.log(offerType === "COMPRA" ? "SELL" : "BUY");
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), `bids`),
        firestore.where(
          "bidType",
          "==",
          offerType === "COMPRA" ? "SELL" : "BUY"
        ),
        firestore.where("operationId", "==", Number(operationId)),
        firestore.where("participantId", "==", prospectId),
        firestore.where("status", "==", "CRIADO")
      ),
    ];
  }, [offerType, operationId, prospectId]);

  const bid = React.useMemo(() => {
    if (!bidData || bidData.length === 0) return undefined;
    return bidData[0] as Bid;
  }, [bidData]);

  return { bid };
}
